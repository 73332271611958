<template>
    <div class="xwdt">
        <ContactUs></ContactUs>
        <div class="xwdt_m">
            <div class="part_header">
                <router-link to="/">首页</router-link> > 新闻动态
            </div>
            <div class="list">
                <router-link class="jump" v-for="(item,index) in top_list" :key='index' :to="'/news/'+index">
                    <p>{{item.theme}}</p>
                    <p>{{item.time}}</p>
                </router-link>
                <!-- <div class="jump" v-for="(item,index) in new_list" :key='index'>
                    <p>{{item.theme}}</p>
                    <p>{{item.time}}</p>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
    import ContactUs from "@/components/common/contactus.vue";
    export default {
        name: 'Gywm',
        props: {
            top_list: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                // new_list: [{
                //         id: 1,
                //         theme: '乐趣海南-狗狗冲冲冲竞猜介绍',
                //         time: '2017-06-27'
                //     },
                //     {
                //         id: 2,
                //         theme: '“乐趣海南”试运营上线',
                //         time: '2017-06-27'
                //     },
                //     {
                //         id: 3,
                //         theme: '融合市场开展即开彩票精细化营销',
                //         time: '2017-06-27'
                //     },
                //     {
                //         id: 4,
                //         theme: '瞻世趣CEO应邀参加长江证券投资交流活动',
                //         time: '2017-02-24'
                //     },
                //     {
                //         id: 5,
                //         theme: '彩票营销与管理专业2017届毕业生毕业论文（调研报告）答辩会圆满举行',
                //         time: '2017-01-06'
                //     },
                //     {
                //         id: 6,
                //         theme: '瞻世趣获丰台区政府颁发“安全生产标准化”证书',
                //         time: '2016-12-30'
                //     },
                //     {
                //         id: 7,
                //         theme: '浅谈彩票游戏玩法的申报',
                //         time: '2016-10-08'
                //     },
                //     {
                //         id: 8,
                //         theme: '京东主题体彩即开彩票上市',
                //         time: '2016-09-30'
                //     },
                //     {
                //         id: 9,
                //         theme: '英国彩票商DJI改名BNN，谋求多元化',
                //         time: '2016-09-27'
                //     },
                //     {
                //         id: 10,
                //         theme: '魔方元获得华人文化融资 颜志应邀出席',
                //         time: '2016-09-02'
                //     },
                //     {
                //         id: 11,
                //         theme: '2016雷达之夜圆满收官',
                //         time: '2016-09-02'
                //     },
                //     {
                //         id: 12,
                //         theme: '新浪彩通：怎么看取消彩票投注站？',
                //         time: '2016-08-31'
                //     },
                //     {
                //         id: 13,
                //         theme: '彩票行业沙龙圆满落幕 行业智库成立',
                //         time: '2016-04-22'
                //     }
                // ]
            }
        },
        components: {
            ContactUs
        },
        methods: {

        }
    }
</script>
<style lang="less" scoped>
    .xwdt {
        margin-top: 13px;
        display: flex;
        justify-content: space-between;

        .xwdt_m {
            width: 700px;
            margin-bottom: 100px;
        }

        .list {
            margin-top: 20px;
            padding: 0 10px;
            .jump {
                display: flex;
                justify-content: space-between;
                height: 30px;
                line-height: 30px;

                p {
                    overflow: hidden;
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    /*!autoprefixer:off*/
                    -webkit-box-orient: vertical;
                    /*autoprefixer:on*/
                    font-size: 16px;
                    color: #666;
                }
            }

            a:hover {
                text-decoration: underline;
            }
        }
    }
</style>